/*!

=========================================================
* Imagine Institute - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// react library for routing
import { Link } from "@reach/router";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

function Index(props) {
  if (typeof document !== `undefined`) document.body.classList.remove("bg-default");
  return (
    <>
      <section className="py-6 pb-9 bg-default">
        <Container fluid>
          <Row className="justify-content-center text-center">
            <Col md="6">
              <h2 className="display-3 text-white">
                Welcome to the Policy Wizard
              </h2>
              <p className="lead text-white">
Whether you’re an experienced child care provider or just starting out, writing your program, staff and parent policies can be overwhelming. It’s often tempting to purchase policies or use templates to cut down on the confusion of reading through hundreds of WAC. With Policy Wizard, you can skip sifting through WAC without sacrificing customization. It’s truly the best of both worlds! Policy Wizard also contains helpful links to resources that can help make WAC clearer, so you feel ready to follow your policies!
 </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section section-lg pt-lg-0 mt--7">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <Row>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                        <i className="ni ni-check-bold" />
                      </div>
                      <h4 className="h3 text-info text-uppercase">
                    Your policy on your time.

                      </h4>

                      <div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                        <i className="ni ni-check-bold" />
                      </div>
                      <h4 className="h3 text-success text-uppercase">
                        Be confident and compliant.
                      </h4>

                      <div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                        <i className="ni ni-check-bold" />
                      </div>
                      <h4 className="h3 text-warning text-uppercase">
                        Stay up-to-date.
                      </h4>

                      <div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-6">
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="order-md-2" md="6">
              <img
                alt="..."
                className="img-fluid"
                src={require("../assets/img/chapter-iso.png")}
              />
            </Col>
            <Col className="order-md-1" md="6">
              <div className="pr-md-5">
                <h2>Work your way through chapters on your own time.</h2>
                <p>
                  We take all the WAC required for licensed child care and split it into easy-to-navigate chapters. You can save and return to your policies any time.
                </p>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-6">
        <Container>
          <Row className="row-grid align-items-center">
            <Col md="6">
              <img
                alt="..."
                className="img-fluid"
                src={require("../assets/img/chapter-iso-2.png")}
              />
            </Col>
            <Col md="6">
              <div className="pr-md-5">
                <h2>Feel confident in your policies.</h2>
                <p>
                Our goal is to help you best understand the WAC, so you can run a successful child care. All child cares are required to follow their policies. With Policy Wizard, you will know your policies inside and out. Before you complete each section, we’ll ask you a couple review questions to make sure you don’t have any questions before moving on!
                </p>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-6">
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="order-md-2" md="6">
              <img
                alt="..."
                className="img-fluid"
                src={require("../assets/img/chapter-iso-3.png")}
              />
            </Col>
            <Col className="order-md-1" md="6">
              <div className="pr-md-5">
                <h2>We stay up-to-date.</h2>
                <p>
                We will continue to update the Policy Wizard to reflect the most current WAC. As WAC changes, just log back in and update your policies.
                </p>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-7 section-nucleo-icons bg-white overflow-hidden">
        <Container>
          <Row className="justify-content-center">
            <Col className="text-center" lg="8">
              <h2 className="display-3">Expert mentoring</h2>
              <p className="lead">
                With our premium package, our team of trained mentors will review your policies and give you advice to best prepare you for your licensing visit. Our mentors have received extensive training on relationship-based professional development and have helped hundreds of providers open their businesses.
              </p>

            </Col>
          </Row>
          <div className="blur--hover">
            <a
              href="https://imaginewa.org"
              target="_blank"
            >
              <div className="icons-container blur-item mt-5">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Row>
                      <Col lg="4">
                      <Card>
                        <CardBody>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                              src={require("../assets/img/team-1.jpg")}
                              style={{ width: "140px" }}
                            />
                          </a>
                          <div className="pt-4 text-center">
                            <h5 className="h3 title">
                              <span className="d-block mb-1">Awes Hassan</span>
                              <small className="h4 font-weight-light text-muted">
                                Mentor
                              </small>
                            </h5>

                          </div>
                        </CardBody>
                      </Card>
                      </Col>
                      <Col lg="4">
                      <Card>
                        <CardBody>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                              src={require("../assets/img/team-2.jpg")}
                              style={{ width: "140px" }}
                            />
                          </a>
                          <div className="pt-4 text-center">
                            <h5 className="h3 title">
                              <span className="d-block mb-1">Marie Keller</span>
                              <small className="h4 font-weight-light text-muted">
                                Mentor
                              </small>
                            </h5>

                          </div>
                        </CardBody>
                      </Card>
                      </Col>
                      <Col lg="4">
                      <Card>
                        <CardBody>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                              src={require("../assets/img/team-3.jpg")}
                              style={{ width: "140px" }}
                            />
                          </a>
                          <div className="pt-4 text-center">
                            <h5 className="h3 title">
                              <span className="d-block mb-1">Tracy Dawson</span>
                              <small className="h4 font-weight-light text-muted">
                                Mentor
                              </small>
                            </h5>

                          </div>
                        </CardBody>
                      </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>

              </div>
              <span className="blur-hidden h3 text-success">
                See all our mentors
              </span>
            </a>
          </div>
        </Container>
      </section>
      <section className="py-7">
        <Container>
          <Row className="row-grid justify-content-center">
            <Col className="text-center" lg="8">
              <h2 className="display-3">
                Sounds awesome?
                <span className="text-success">
                  Sign up now and get started on your policies.
                </span>
              </h2>
              <p className="lead">
                With Policy Wizard you’re not alone. We will guide you every step of the way as you craft your unique policies for your unique child care.
              </p>
              <div className="btn-wrapper">
                <Button
                  className="btn-neutral mb-3 mb-sm-0"
                  color="default"
                  href="https://imaginewa.org"
                  target="_blank"
                >
                  <span className="btn-inner--text">Explore</span>
                </Button>
                <Button
                  className="btn-icon mb-3 mb-sm-0"
                  color="info"
                  href="https://imaginewa.org"
                  target="_blank"
                >

                  <span className="btn-inner--text">Sign up</span>
                  <Badge
                    className="badge-md badge-floating border-white"
                    color="danger"
                    pill
                  >
                    Free
                  </Badge>
                </Button>
              </div>

            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Index;
